import {
    Grid, Text,
} from "@mantine/core";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { addSubCategoryThunk, getAllActiveCategoriesThunk } from "../../Redux/Thunks/subcategoryThunk";
import { subcategorySelector } from "../../Redux/Selectors/subcategorySelector";
import { ReactComponent as DownArrowSvg } from "../../images/down-arrow-6402.svg"
import { setIsLoggedIn } from "../../Redux/userSlice";


const imagefolder = "subcategories"

const AddEditMain = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [addMode, setAddMode] = useState(true)

    const { activeCategories }: any = useSelector(subcategorySelector)

    function logoutUser() {
        dispatch(setIsLoggedIn());
        localStorage.removeItem("currentToken");
        localStorage.removeItem("userId");
        navigate("/login")
    }

    function checkLoginToken() {
        let token = localStorage.getItem("currentToken")
        if (!token) {
            logoutUser()
        }
        else {
            return token
        }
    }


    const [dataVal, setDataVal] = useState({
        name: "",
        image: "",
        category_id: "",
        _id: ''
    })

    useEffect(() => {
        async function getActiveCategories() {
            let token = checkLoginToken()
            let response: any = await dispatch(getAllActiveCategoriesThunk({ token: token }))
            if (response.payload.response && response.payload.response.status) {
                if (response.payload.response.status == 401) {
                    logoutUser()
                }
                toast.error(response.payload.response.data.message)
            }
        }
        getActiveCategories()
    }, [])

    useEffect(() => {
        if (location && location.state && Object.entries(location.state).length > 0) {
            setDataVal({
                name: location.state.name || "",
                image: location.state.image || "",
                category_id: location.state.category_id ? location.state.category_id._id : "",
                _id: location.state._id || ''
            })
            setAddMode(false)
        }
    }, [])

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        category_id: Yup.string().trim().required("Category is required"),
        ...(addMode ? { image: Yup.string().required("Image is required") } : {}),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: dataVal.name,
            image: dataVal.image,
            category_id: dataVal.category_id
        },
        validationSchema,
        onSubmit: async (data, { resetForm }) => {

            await handleCreateSubCategory(data)
            if (addMode) {
                setFile(null)
                setFileDataURL(null)
                setDataVal({
                    name: "",
                    image: "",
                    category_id: "",
                    _id: ""
                })
                resetForm()
                navigate("/subcategories")
            }
        }
    });


    const [file, setFile] = useState<any>(null);
    const [fileDataURL, setFileDataURL] = useState(null);

    const changeHandler = (e: any) => {
        const file = e.target.files[0];

        setFile(file);
    }

    useEffect(() => {
        let fileReader: any, isCancel = false;
        if (file) {
            fileReader = new FileReader();
            fileReader.onload = (e: any) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setFileDataURL(result)
                }
            }
            fileReader.readAsDataURL(file);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }

    }, [file]);


    const handleCreateSubCategory = async (data: any) => {
        try {
            let token = checkLoginToken()

            let payload: any = { ...data }
            payload.token = token
            payload.is_image_uploaded = 0

            if (file) {
                let formData = new FormData()

                formData.append("folder", imagefolder);

                formData.append("image", file);

                let resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadImage`, formData, { 'headers': { 'Authorization': `${token}` } })
                if (resp.statusText == "OK") {
                    let filename = resp.data.body.filename;
                    payload.image = filename;
                    payload.is_image_uploaded = 1
                }
            }
            else {
                payload.image = data.image
            }

            if (dataVal._id) payload._id = dataVal._id;

            let response: any = await dispatch(addSubCategoryThunk(payload))

            if (response.payload.response && response.payload.response.status) {
                if (response.payload.response.status == 401) {
                    logoutUser()
                }
                toast.error(response.payload.response.data.message)
            }
            else {
                toast.success(response.payload.data.message)
                navigate("/subcategories")
            }
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <div className="main_layout">

                    <h2>
                        {addMode ? "Add" : "Edit"} Subcategory
                    </h2>
                    <div className="add_edit_box">
                    <form className='add-category' onSubmit={formik.handleSubmit}>
                        <div className="flex-fg">
                            <div className='form-group'>
                                <label>SubCategory Name</label>
                                <input
                                    name="name"
                                    type="text"
                                    placeholder="Enter Name"
                                    className={
                                        'form-control' +
                                        (formik.errors.name && formik.touched.name
                                            ? ' is-invalid'
                                            : '')
                                    }
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                                <div className="invalid-feedback">
                                    {formik.errors.name && formik.touched.name
                                        ? formik.errors.name
                                        : null}
                                </div>
                            </div>

                            <div className='form-group'>
                                <label>Select Category</label>

                                <div className="select_icon">
                                    <DownArrowSvg />
                                    <select
                                        name="category_id"
                                        className={
                                            'form-control' +
                                            (formik.errors.category_id && formik.touched.category_id
                                                ? ' is-invalid'
                                                : '')
                                        }
                                        onChange={formik.handleChange}
                                        value={formik.values.category_id}
                                    >
                                        <option value="">
                                            Select a category
                                        </option>

                                        {
                                            activeCategories && activeCategories.map((ele: any) => {
                                                return (
                                                    <option value={ele._id}>
                                                        {ele.name}
                                                    </option>
                                                )
                                            })
                                        }

                                    </select>
                                </div>

                                <div className="invalid-feedback">
                                    {formik.errors.category_id && formik.touched.category_id
                                        ? formik.errors.category_id
                                        : null}
                                </div>
                            </div>



                            <div className='form-group'>
                                <label>Image</label>
                                <input
                                    name="image"
                                    type="file"
                                    value={addMode ? formik.values.image : ""}
                                    className={
                                        'form-control'
                                    }
                                    accept='.png, .jpg, .jpeg'
                                    onChange={(e: any) => {
                                        formik.handleChange(e)
                                        changeHandler(e)
                                    }}
                                />
                                <div className="invalid-feedback">
                                    {formik.errors.image && formik.touched.image
                                        ? formik.errors.image
                                        : null}
                                </div>
                            </div>

                            {fileDataURL ?
                                <p className="img-preview-wrapper">
                                    {
                                        <img src={fileDataURL} style={{ height: "80px" }} alt="preview" />
                                    }
                                </p> : null}

                            {(!fileDataURL && dataVal.image != '') ?
                                <p className="img-preview-wrapper">
                                    {
                                        <img src={dataVal.image} style={{ height: "80px" }} alt="preview" />
                                    }
                                </p> : null}
                        </div>

                        <button type="submit" className='btn main_btn1 text-white mt-4'>Submit</button>
                    </form>
                    </div>
        </div>
    );
};

export default AddEditMain;
